import {
  Box,Tab,Tabs
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { Container, Grid } from '@mui/material';
import AllShopsTable from './AllShopsTable';
import { useLocation } from 'react-router-dom';
import PremiumShopsTable from './PremiumShops';
import ActivePremiumShopsTable from './ActivePremiumShops';
import AllShopsMessage from './AllShopsMessage';
import PremiumShopsMessageTable from './AllPremiumShopsMessage'
import ActivePremiumShopsMessageTable from './AllActivePremiumShopsMessages';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const AllShops = () => {
  const location = useLocation();
   const [value, setValue] = useState<number>(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
     setValue(newValue);
   };
  const headerText = location.pathname.includes('active-premium-shops')
    ? 'active-premium'
    : location.pathname.includes('premium-shops')
      ? 'premium' 
      : location.pathname.includes('message') ?
        'message'
    : 'all';
  return (
    <>
      <Helmet>
        <title>Shops - All</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} style={{ paddingTop: '40px' }}>
            {headerText === 'active-premium' ? (
              <ActivePremiumShopsTable />
            ) : headerText === 'premium' ? (
              <PremiumShopsTable />
            ) : headerText === 'message' ? (
              <>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      centered
                      sx={{ margin: 1 }}
                    >
                      <Tab label="All Shops" {...a11yProps(0)} />
                      <Tab label="Premium Shops" {...a11yProps(1)} />
                      <Tab label="Active Premium Shops" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <AllShopsMessage />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <PremiumShopsMessageTable />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <ActivePremiumShopsMessageTable/>
                  </CustomTabPanel>
                </Box>
              </>
            ) : (
              <AllShopsTable />
            )}
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export default AllShops;
