import axios from 'axios';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';
import { ShopsDetails } from 'src/models/Shops';

export interface UserLoginData {
  email: string;
  password: string;
}

export class AuthService {
  //keys
  private static readonly TOKEN_KEY = 'token';

  //log in
  public static async userLogin(
    userLoginData: UserLoginData
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('inventryadminlogin');

    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      userLoginData
    );

    if (res.success && res.data.role === 'SUPER_ADMIN' || res.success && res.data.role === 'INVENTORY_ADMIN'|| res.success && res.data.role === 'ADMIN'|| res.success && res.data.role === 'CUSTOMER'|| res.success && res.data.role === 'CUSTOMER_SUPPORT') {
      localStorage.setItem(AuthService.TOKEN_KEY, res.data.token); //TODO read token from cookie and remove this implementation
    }

    return res;
  }

  public static async userLoginGoogle(
    userLoginData: any
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('inventryadminloginGoole');

    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      userLoginData
    );

    if (res.success && res.data.role === 'SUPER_ADMIN' || res.success && res.data.role === 'INVENTORY_ADMIN'|| res.success && res.data.role === 'ADMIN'|| res.success && res.data.role === 'CUSTOMER'|| res.success && res.data.role === 'CUSTOMER_SUPPORT') {
      localStorage.setItem(AuthService.TOKEN_KEY, res.data.token); //TODO read token from cookie and remove this implementation
    }

    return res;
  }

  public static async getMe(): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('me');

    const res = await axios.get<void, AppResponse<any>>(ep);
    if (res.error) {
      localStorage.removeItem(AuthService.TOKEN_KEY);
    }

    return res;
  }

  public static getToken(): string | null {
    return localStorage.getItem(AuthService.TOKEN_KEY); //TODO read token from cookie and remove this implementation
  }

  public static async getUserByUserId1(
    userId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getUserByUserId/${userId}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async getUserByUserId(userId: any): Promise<AppResponse<any>> {
    console.log("userId")
    const ep = Util.apiAuthUrl(`getUserByUserId/${userId}`);

    const res = await axios.get<void, AppResponse<any>>(ep);
   

    return res;
  }

  //get subscription details by id
  public static async getSubscriptionDetails(subscriptionId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`get-subscription-details/${subscriptionId}`);
    const res = await axios.get<void, AppResponse<any>>(ep);
    return res;
  }

  //get shop by id
  public static async getShopByShopId(shopId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`get-shop-by-id-admin/${shopId}`);

    const res = await axios.get<void, AppResponse<any>>(ep);
   

    return res;
  }

   public static async getShopDashboardByShopId(shopId: any): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`get-shop-data-by-id-admin/${shopId}`);

    const res = await axios.get<void, AppResponse<any>>(ep);
   

    return res;
  }
}
