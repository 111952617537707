import {
  Box, Button,
  CircularProgress, Dialog, DialogContent, Typography, styled,InputLabel,Select,MenuItem
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import moment from 'moment';
import { format } from 'date-fns';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}



function EditPaymentTypePopUp({ open,selectedShop, onClose, shopId, userId, allShops, setAllShops, index }) {
  const [loading, setLoading] = useState(false);
  const [subscriptionPlans ] = useState<string[]>(['MONTHLY', 'YEARLY']);
  const [subPlan, setPlan] = useState('');
  const [timePeriod, setTimePeriod] = useState(null);
  useEffect(() => {
    const startDate = moment(selectedShop.paymentStartDate, 'DD/MM/YYYY');
    const end = moment(selectedShop.paymentEndDate, 'DD/MM/YYYY');
    const totalDays = end.diff(startDate, 'days');
    if (totalDays > 364) {
      setPlan('YEARLY');
    } else {
      setPlan('MONTHLY');
    }
  }, [selectedShop]);

  
  const validationSchema = yup.object({
    subPlan: yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      subPlan: subPlan || subscriptionPlans
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateShop(values);
      formik.resetForm();
    }
  });

  const updateShop = (values) => {
    onClose();
    const data = {
      plan: values.subPlan,
      id:userId
    };
    InventoryService.updateSubscriptionPlan(data).then((res) => {
      if (res.success) {
        const createdAtDate = new Date(res.data.updatedAt);
        const endDate = new Date(createdAtDate);

        endDate.setFullYear(
          subPlan == 'YEARLY'
            ? createdAtDate.getFullYear() + 1
            : createdAtDate.getFullYear()
        );

        endDate.setMonth(
          subPlan == 'MONTHLY'
            ? createdAtDate.getMonth() + 1
            : createdAtDate.getMonth()
        );
        const startDateStr = res.data.updatedAt;
        const endDateStr = endDate.toISOString();

        const startDateFormat = new Date(startDateStr);
        const endDateFormat = new Date(endDateStr);

        const formattedStartDate = format(startDateFormat, 'dd/MM/yyyy');
        const formattedEndDate = format(endDateFormat, 'dd/MM/yyyy');
        const updatedTemplageProduct = [...allShops];
        updatedTemplageProduct[index] = {
          ...updatedTemplageProduct[index],
          paymentType: 'BANK',
          paymentStatus: 'PAID',
          remainingDays: subPlan == 'YEARLY' ? 365 : 30,
          paymentStartDate: formattedStartDate,
          paymentEndDate: formattedEndDate
        };
        setAllShops(updatedTemplageProduct);
        Swal.fire('Subscription Updated', 'Successfully', 'success');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  
  const handlesubPlanChange = (event) => {
    const selectedsubPlan = event.target.value;
    setPlan(selectedsubPlan);
    formik.setFieldValue('subPlan', selectedsubPlan);
  };
  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Edit Shop
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent dividers>
          {selectedShop ? (
            <form onSubmit={formik.handleSubmit}>
              <InputLabel>Select Payment</InputLabel>
              <Select
                labelId="subPlan-label"
                id="subPlan"
                name="subPlan"
                value={subPlan}
                onChange={handlesubPlanChange}
                error={
                  formik.touched.subPlan && Boolean(formik.errors.subPlan)
                }
                sx={{ width: '100%' }}
              >
                {subscriptionPlans.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ height: 20 }}></div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Update
              </Button>
            </form>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </DialogContent>
      )}
    </BootstrapDialog>
  );
}

export default EditPaymentTypePopUp;